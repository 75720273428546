var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "role-list" },
    [
      _c(
        "app-tree",
        { ref: "appTree", attrs: { opt: _vm.opt } },
        [
          _c(
            "template",
            { slot: "info" },
            [
              _vm.activeName == "tree" && _vm.menuData.length
                ? _c(
                    "el-button",
                    {
                      staticClass: "role_list_save",
                      on: { click: _vm.saveRoleMenu }
                    },
                    [_vm._v("保存")]
                  )
                : _vm._e(),
              _c(
                "el-tabs",
                {
                  staticClass: "info-box",
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "角色信息", name: "form" } },
                    [
                      _c(
                        "el-form",
                        {
                          attrs: {
                            model: _vm.form,
                            "label-width": "80px",
                            disabled: ""
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "角色名称" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.rolename,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "rolename", $$v)
                                  },
                                  expression: "form.rolename"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "角色类型" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.roleTypeStr,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "roleTypeStr", $$v)
                                  },
                                  expression: "form.roleTypeStr"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "添加时间" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.createdDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "createdDate", $$v)
                                  },
                                  expression: "form.createdDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "角色描述" } },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea" },
                                model: {
                                  value: _vm.form.description,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "description", $$v)
                                  },
                                  expression: "form.description"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "操作权限", name: "tree" } },
                    [
                      _c("el-tree", {
                        ref: "trees",
                        attrs: {
                          "default-expand-all": "",
                          data: _vm.menuData,
                          "default-checked-keys": _vm.opt.defaultChecked,
                          "node-key": "id",
                          "show-checkbox": "",
                          "check-strictly": "",
                          props: {
                            children: "children",
                            label: "label"
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }